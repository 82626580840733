/* Container */
.login-container {
    background: linear-gradient(135deg, #007bff, #0056b3);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Card */
  .login-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Title and Subtitle */
  .login-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0.5rem;
  }
  
  .login-subtitle {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 1.5rem;
  }
  
  /* Form */
  .login-form {
    display: flex;
    flex-direction: column;
  }
  
  .login-label {
    font-size: 0.9rem;
    color: #333333;
    text-align: left;
    margin-bottom: 0.3rem;
  }
  
  .login-input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin-bottom: 1rem;
    transition: border-color 0.3s;
  }
  
  .login-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Login Button */
  .login-button {
    background-color: #007bff;
    color: #ffffff;
    padding: 0.8rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }
  
  /* Register Link */
  .login-register-link {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .register-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 0.9rem;
    text-decoration: underline;
    padding: 0;
  }
  
  .register-button:hover {
    color: #0056b3;
    text-decoration: none;
  }