/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

/* Apply Google Fonts */
body {
  font-family: 'Roboto', sans-serif; /* Use Roboto as the default font */
}

/* Dashboard Container */
.dashboard-container {
  background-color: #1c1c1c;
  color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header */
.dashboard-header {
  background-color: #282c34;
  padding: 1rem;
  text-align: center;
  font-family: 'Open Sans', sans-serif; /* Use Open Sans for headers */
  font-size: 1.5rem;
  font-weight: 600;
}

/* Navigation Tabs */
.dashboard-nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Wrap tabs on smaller screens */
  margin: 1rem 0;
  gap: 0.5rem; /* Add spacing between buttons */
}

.dashboard-tab {
  padding: 0.5rem 1rem;
  margin: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  font-family: 'Roboto', sans-serif;
  flex: 1; /* Allow buttons to resize for mobile */
  max-width: 200px; /* Limit width for larger screens */
  text-align: center;
}

.dashboard-tab.active {
  background-color: #0056b3;
}

.dashboard-tab:hover {
  background-color: #0056b3;
}

/* Main Section */
.dashboard-main {
  flex: 1;
  padding: 2rem;
}

/* Table Styles */
.table-container {
  background-color: #282c34;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  overflow-x: auto; /* Allow horizontal scroll for small screens */
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: #343a40;
  font-family: 'Roboto', sans-serif;
}

.table th,
.table td {
  border: 1px solid #495057;
  padding: 0.8rem;
  text-align: left;
  color: #f8f9fa;
  font-size: 0.9rem;
}

/* Add Shift Form */
.add-shift-form-container,
.add-callout-form-container {
  background-color: #282c34;
  padding: 1.5rem;
  border-radius: 8px;
}

.add-shift-form label,
.add-callout-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
}

.add-shift-form input,
.add-shift-form select,
.add-shift-form textarea,
.add-callout-form input,
.add-callout-form select,
.add-callout-form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #1c1c1c;
  color: #f8f9fa;
  border: 1px solid #495057;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
}

.add-shift-button,
.add-callout-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Full width on mobile */
  max-width: 200px; /* Restrict width on larger screens */
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.add-shift-button:hover,
.add-callout-button:hover {
  background-color: #0056b3;
}

/* Daily Count Section */
.daily-count {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Wrap items for smaller screens */
  gap: 1rem; /* Add spacing between items */
  padding: 1rem;
  background-color: #282c34;
  color: rgb(0, 0, 0);
  border-bottom: 2px solid hsl(0, 0%, 0%);
}

.count-item {
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  min-width: 120px;
  color: white;
  flex: 1; /* Allow items to resize on mobile */
  max-width: 200px; /* Restrict width on larger screens */
  font-family: 'Open Sans', sans-serif;
}

.count-item h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.count-item p {
  margin: 0.5rem 0 0;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .dashboard-tab {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    max-width: 100%; /* Allow full width for buttons */
  }

  .add-shift-button,
  .add-callout-button {
    font-size: 1rem;
    padding: 0.6rem;
    max-width: 100%; /* Full width on mobile */
  }

  .count-item {
    font-size: 1rem;
    padding: 0.8rem;
    min-width: 100px;
  }

  .table-container {
    padding: 0.5rem;
  }

  .table th,
  .table td {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

.logout-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #ff1a1a;
}

.disabled-input {
  background-color: #e9ecef; /* Light grey background for disabled input */
  color: #6c757d; /* Text color for disabled state */
  border: 1px solid #ced4da; /* Match disabled input border style */
  pointer-events: none; /* Prevent interactions */
}