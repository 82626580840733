/* General Container */
.register-container {
    background: linear-gradient(135deg, #28a745, #1c7430);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Card */
  .register-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  /* Title and Subtitle */
  .register-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0.5rem;
  }
  
  .register-subtitle {
    font-size: 1rem;
    color: #666666;
    margin-bottom: 1.5rem;
  }
  
  /* Form */
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .register-label {
    font-size: 0.9rem;
    color: #333333;
    text-align: left;
    margin-bottom: 0.3rem;
  }
  
  .register-input {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #cccccc;
    border-radius: 8px;
    margin-bottom: 1rem;
    transition: border-color 0.3s;
  }
  
  .register-input:focus {
    border-color: #28a745;
    outline: none;
  }
  
  /* Button */
  .register-button {
    background-color: #28a745;
    color: #ffffff;
    padding: 0.8rem;
    font-size: 1rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .register-button:hover {
    background-color: #1c7430;
  }
  
  .register-login-link {
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .register-login-link a {
    color: #28a745;
    text-decoration: none;
  }
  
  .register-login-link a:hover {
    text-decoration: underline;
  }